import type { DeepReadonly, Ref } from 'vue'
import type { NotificationType } from '@/entities/notification'

export interface GlobalNotification {
  title: string
  text?: string
  delay?: number
  type?: NotificationType
  action?: { icon: string; name: string; onClick: () => void }
}

export interface UseGlobalNotification {
  notification: DeepReadonly<Ref<GlobalNotification | null>>
  setNotification: (notification: GlobalNotification | null) => void
  clearNotification: () => void
}

export function useGlobalNotification(): UseGlobalNotification {
  const notification = useState<GlobalNotification | null>('notification', () => null)

  function setNotification(n: GlobalNotification | null): void {
    notification.value = n
  }

  function clearNotification(): void {
    notification.value = null
  }

  return {
    notification: readonly(notification),
    setNotification,
    clearNotification,
  }
}
